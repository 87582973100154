









































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { EditState } from '@/store/models.def';

@Component({
  components: {},
})
export default class MutableInputContainer extends Vue {
  @Prop({ default: 'done' }) public value!: EditState;
  @Prop({ default: true }) public editable!: boolean;

  public editState: EditState = 'done';

  @Watch('value')
  public onValueChanged(val: EditState, oldVal: EditState) {
    if (!this.editable && val !== 'done') {
      return;
    }
    this.editState = val;
  }

  public dispatchEditState() {
    this.$emit('input', this.editState);
    this.$emit('change', this.editState);
  }

  public edit() {
    this.editState = 'active';
    this.dispatchEditState();
  }
  public cancel() {
    this.editState = 'done';
    this.dispatchEditState();
  }

  public submit() {
    this.editState = 'sending';
    this.dispatchEditState();
    this.$emit('submit');
  }
}
